const en = {
    translation: {
        /*============= LOGIN */
        BAD: "Lunexia",
        download_myopinion: "Download Lunexia Social Hub",
        with_myopinion_share_and_stay: "Introducing the Lunexia Social Hub – your gateway to enhanced collaboration, knowledge sharing, and networking With our secure and user-friendly platform, you can connect with fellow AfDB professionals, access valuable resources, and engage in meaningful discussions Whether you’re seeking project insights, sharing best practices, or building connections across the AfDB community, the Wonko Social Hub is your go-to destination Join us today and unlock the power of collaboration.",
        login_now_to_interact_with_thousands_of_people: "Log in now to interact with your AfDB colleagues.",
        connect_with: "Connect with",
        login_with_gmail: "Login with Gmail",
        login_with_facebook: "Login with facebook",
        login_with_apple: "Login with Apple",
        create_an_account: "Create an account",
        continue: "Continue",
        reset: "Reset",
        or: "or",
        and: "and",
        login: "Login",
        logout: 'Logout',
        email: "Email",
        password: "Password",
        new_password: "New password",
        confirm_password: "Confirm password",
        forgot_password: "Forgot your password",
        forgot_your_password: "Forgot your password ?",
        please_enter_your_account_email_below_to_reset_it: "Please enter your account email below to reset it.",
        terms_and_conditions: "Terms and Conditions",
        privacy_policy: "Privacy Policy",
        french_france: "French (France)",
        english_us: "English (US)",
        link_terms_of_use: "https://payesha.com/legal/en/conditions-generales.html",
        link_privacy_policy: "https://payesha.com/legal/en/conditions-generales.html",
        incorrect_email_address_or_password: "Incorrect email address or password.",
        an_error_occurred_please_try_again_later: "An error occurred, please try again later.",
        trend_for_you: "Trend for you",
        enter_your_new_password: "Enter your new password",

        /*================ REGISTER */
        sign_up: "Sign up",
        you_do_not_have_an_account: "You do not have an account ? Create your account, it takes less than a minute",
        name: "Name",
        surname: "Surname",
        nickname: "Nickname",
        civility: "Civility",
        phone_number: "Phone number",
        city: "City",
        country: "Country",
        indicative: "Indicative",
        register: "Register",
        confirm: "Confirm",
        confirmation_code: "Confirmation code",
        account_verification: "Account verification",
        a_6_digit_code_has_just_been_sent: "A 6-digit code has just been sent to your email address",
        and_the_telephone_number_associated_with_the_account: "and the telephone number associated with the account",
        if_you_dont_see_the_email_look_in_your_spam: "If you don't see the email, look in your spam.",
        password_reset: "Password reset",

        /*============ ERROR INPUT */
        your_surname_must_contain_at_least_4_characters: "Your surname must contain at least 4 characters.",
        your_nickname_must_contain_at_least_4_characters_only_underscore: "Your nickname must contain at least 4 characters, the underscore (_) is allowed.",
        invalid_confirmation_code: "Invalid confirmation code.",
        invalid_confirmation_code_at_least_6_characters: "Invalid confirmation code, the confirmation code must contain at least 6 characters.",
        invalid_email: "Invalid email.",
        invalid_password: "Invalid password, your password must contain at least 8 characters.",
        passwords_do_not_match: 'passwords do not match.',

        /*============== ERROR REQUEST */
        the_email_is_already_taken: "The email is already taken",
        the_email_is_already_taken_or_you_are_not_authorized: "The email is already taken or you are not authorized to use this app.",
        no_internet_connection: "No internet connection, check your internet connection and try again.",
        this_email_does_not_have_an_account: "This Email does not have an account.",

        /*============ OPINIONS */
        audience: "Audience",
        public: "Public",
        recent: 'Recent',
        loading_recent: 'Loading Recent...',
        random: 'Random',
        forYou: 'For you',
        loading_random: 'Loading Random...',
        loading_for_you: 'Loading for you...',
        viral: 'Viral',
        loading: 'Loading...',
        loading_viral: 'Loading Viral...',
        loading_audience: 'Loading contacts...',
        loading_opinion: 'Loading opinions...',
        loading_profile: 'Loading profile...',

        //========= PROFILE
        profile: 'Profile',
        profile_picture: 'Profile picture',
        cover_picture: 'Cover picture',
        setting: 'Settings',
        language: 'Language',
        account: 'Account',
        update: 'Update',
        update_your_login_settings_associated_with_your_account: 'Update your connection settings associated with your account.',

        buddy: "Contact",
        buddies: "Contacts",
        become_buddy: 'Become contact',
        you_are_buddy: 'You are contact',

        publish: 'Publish',
        inbox: 'Inbox',
        groups: 'Groups',
        group: 'Group',
        contact: 'Contact',
        contacts: 'Contacts',
        search: 'Search',
        fan: 'Follower',
        fans: 'Followers',
        fan_of: 'Following',
        become_fan: 'Follow',
        you_are_fan: 'Following',
        approve: 'Accept',
        approveRequest: 'Accept request',
        Received: 'Received',
        request: 'Request',
        sent: 'Sent',
        cancel: ' Cancel',
        block: 'Block',
        blocked: 'Blocked',
        unlock: 'Unlock',
        no_buddy: 'No contact',
        network: 'Network',

        follower: 'Follower',
        followers: 'Followers',
        following: 'Following',

        like: 'Like',
        likes: 'Likes',
        post: 'Post',
        posts: 'Posts',
        no_user: 'No contact',

        //========== BADGE

        /* Goal */
        goal: 'Life goal',
        love: 'Love',
        god: 'God',
        money: 'Money',
        power: 'Power',
        fame: 'Fame',
        family: 'Family',
        humanitary: 'Humanitarian',
        patriot: 'Patriot',
        peace: 'Peace',
        freedom: 'Freedom',
        saveThePlanet: 'Save The Planet',

        /* Business */
        business: 'Business',
        technology: 'Technology',
        finance: 'Finance',
        arts: 'Arts',
        health: 'Health',
        foodIndustry: 'Food industry',
        education: 'Education',
        law: 'Law',
        restaurant: 'Catering',
        communication: 'Communication',
        services: 'Services',
        proAthlete: 'Pro-Athlete',
        agriculture: 'Agriculture',


        /* Hooby */
        hobby: 'Hobby',
        travel: 'Travel',
        sport: 'Sport',
        cinema: 'Cinema',
        nightLife: 'Dance night',
        communityLife: 'Community life',
        art: 'Art',
        gardening: 'Gardening',
        hike: 'Hiking',
        hunting: 'Hunting',
        fishing: 'Fishing',
        music: 'Music',
        videoGame: 'Video game',


        /* Food */
        food: 'Food',
        vegetarian: 'Vegetarian',
        meat: 'Meat',
        fish: 'Fish',
        bread: 'Bread',
        cake: 'Cake',
        pasta: 'Pasta',
        soup: 'Soup',
        seaFood: 'Sea food',
        cereals: 'Cereals',
        dairy: 'Dairy',
        rice: 'Rice',
        wildMeat: 'Wild meat',

        /* Color */
        color: 'Color',
        red: 'Red',
        blue: 'Blue',
        green: 'Green',
        yellow: 'Yellow',
        orange: 'Orange',
        black: 'Black',
        white: 'White',
        purple: 'Purple',
        pink: 'Pink',
        brown: 'Brown',
        grey: 'Gray',
        cyan: 'Cyan',
        beige: 'Beige',

        select_at_least_one_badge_per_category: 'Select at least one badge per category.',
        you_cannot_modify_your_badges: 'You cannot update your badges because you last updated it less than 30 days ago.',

        edit_profile: 'Edit profile',
        edit: 'Edit',
        profile_title: 'Profile title',
        profile_description: 'Profile description',
        profile_email: 'Profile Email',
        web_site: 'Web site',

        account_suspended: 'Account suspended',
        account_suspended_desc: 'MyOpinion suspends accounts that violate the MyOpinion Rules.',

        account_blocked: 'This user has blocked you',
        account_blocked_desc: 'You cannot access this profile because this user has blocked you.',

        account_desactived: 'Account desactived',
        account_desactived_desc: 'You cannot access this profile because this user has deactivated their account.',

        for_more_information_please_carefully_read_our: 'For more information, please carefully read our',

        subscribe_to_email_notification: 'Unsubscribe to email notification',
        yes: 'Yes',
        no: 'No',
        security_and_password: 'Security and password',
        balance: 'Balance',
        subscriptions: 'Subscriptions',

        current_password: 'Current password',
        confirmation_of_new_password: 'Confirmation of new password',
        your_current_password_is_incorrect: 'Your current password is incorrect.',
        your_password_has_been_updated: 'Your password has been updated.',

        coming_soon: 'Coming soon',
        reload: 'Reload',
        collect: 'Collect',
        no_transactions_available: 'No transactions available',

        buying_coins: 'Digital Gifts',
        verified_account: 'Verified account',
        premium_account: 'Premium account',
        sponsored_opinion: 'Sponsored opinion',
        send_gift_to: 'Send gift to',
        gift_received_from: 'Gift received from',
        monthly_subscription: 'Monthly Subscription',
        annual_subscription: 'Annual subscription',

        // create opinion
        create_opinion: 'Create post',
        activate_vote: 'Activate vote',
        enter_your_opinion: 'Enter your opinion',
        publishing_in_progress: 'Publishing in progress...',
        add_to_your_opinion: 'Add to your post',
        publish: 'Publish',
        choice: 'Choice',
        at: 'at',
        mandatory: 'mandatory',
        you_must_add_a_description_to_your_opinion: 'You must add a description to your opinion.',
        the_first_two_choices_are_mandatory: 'The first two choices are mandatory.',
        video_too_large_please_select_a_video_less_than_50mb: 'Video too large, please select a video less than 50mb.',

        mute: 'Mute',
        unmute: 'Unmute',
        report: 'Report',
        download: 'Download',
        delete: 'Delete',
        available: 'Available',
        unavailable: 'Unavailable',
        sorry: 'Sorry',

        // REPORT
        opinionTitle: 'opinion',
        commentTitle: 'comment',
        conversationTitle: 'conversation',
        reportOpinion: {
            sexualContent: 'Sexual content',
            violent: 'Violent or repulsive content',
            hateful: 'Hateful or abusive content',
            harassment: 'Harassment or bullying',
            harmful: 'Harmful or dangerous acts',
            childAbuse: 'Child abuse',
            infrigeRight: 'Infringes my rights',
            terrorism: 'Promotes terrorism',
            spam: 'Spam or misleading',
        },
        reportComment: {
            commercial: 'Unwanted commercial content or spam',
            sexual: 'Pornography or sexual explicit material',
            childAbuse: 'Child abuse',
            hateSpeech: 'Hate speech or graphic violence',
            harassment: 'Harassment or bullying',
        },
        other: 'Other',

        responseReportTitle: 'We are sorry you had this bad experience on Lunexia Social Hub.',
        responseReportDescription:
            'Your report has successfully been sent. We will process it and take further actions if necessary.',

        responseOpinionTitle: 'Opinion reported',
        responseOpinionDescription:
            'We will review and take further actions if necessary.',

        responseCommentTitle: 'Comment reported',
        responseCommentDescription:
            'We will review and take further actions if necessary.',

        responseConversationTitle: 'Conversation reported',
        responseConversationDescription:
            'We will review and take further actions if necessary.',

        enter_the_reason: 'Enter the reason...',


        opinion_for_android: 'Opinion for Android',
        opinion_for_ios: 'Opinion for IOS',
        opinion_for_huawei: 'Opinion for Huawei',
        opinion_for_web: 'Opinion for Web',
        opinion_for: 'Opinion for',

        search: 'Search',
        person: 'Person',
        people: 'People',
        search_by_badge: 'Search by badge',
        search_by_category: 'Search by category',
        people_you_match: 'People you match',
        colleagues_who_match: 'Colleagues who match',

        my_agoras: 'My groups',
        my_requests: 'My requests',
        my_invites: 'My invites',
        create_agora: 'Create groups',
        create: 'Create',
        remove: 'Remove',
        title: 'Title',
        participate: 'Participate',
        name_of_the_group: 'Name of the group',
        description_of_the_group: 'Description of the group',
        private_group: 'Private group',
        public_group: 'Public group',
        publish_in_the_group: 'Publish in the group',

        no_conversations_available: 'No conversations available',
        no_conversations_available_desc: 'Enter the name of your friend to whom you want to write.',

        agora_you_might_be_interested_in: 'Group you might be interested in',
        show_your_last_seen: 'Show your last seen',
        hide_your_last_seen: 'Hide your last seen',

        sharing_your_last_connection_has_been_successfully_activated: 'Sharing your last connection has been successfully activated.',
        sharing_your_last_login_has_been_successfully_disabled: 'Sharing your last login has been successfully disabled.',

        delete_all_messages: 'Delete all messages',
        write_your_message: 'Write your message',

        member: 'Member',
        members: 'Members',
        view_all: 'View all',
        poll: 'Poll',

        privacy: 'Privacy',
        event: 'Event',
        events: 'Events',
        event_title: 'Event title',
        event_type: 'Event type',
        public_event: 'Public event',
        location_of_the_event: 'Event type',
        event_date: 'Event date',
        event_time: 'Event time',

        enter_the_event_title: 'Enter the event title.',
        enter_the_event_description: 'Enter the event description.',
        enter_the_location_Of_the_event: 'Enter the location Of the event.',
        enter_the_date_of_the_event: 'Enter the date of the event.',
        enter_the_time_of_the_event: 'Enter the time of the event.',

        participate: 'Participate',
        request_sent: 'Request sent',

        // INTRANET
        hr_search: 'HR search',
        myRequests: 'My requests',
        myRequestDesc: 'Management of HR requests: contract, leave request, communication etc.',
        myBalance: 'My holiday balance',
        myBalanceDesc: 'This service allows you to securely view your monthly pay slips.',
        myEvents: 'My events',
        myEventDesc: 'Discover all our activities for your corporate events and book an incredible moment in just a few clicks.',
        myCalendar: 'My calendar',
        myCalendarDesc: 'A useful scheduling tool for scheduling employee shifts.',
        sharedDocuments: 'Shared documents',
        sharedDocumentDesc: 'The official AfDB Web Intranet.',
        newRequest: 'New request',
        request: 'Request',
        requests: 'Requests',
        requestsSent: 'Requests sent',
        requestSent: 'Requests sent',
        requestsReceived: 'Requests received',
        noRequestAvailable: 'No request available',
        noEventAvailable: 'No event available',
        weEncounteredAnErrorPleaseTryAgain: 'We encountered an error, please try again',
        treatmentFailure: 'Treatment failure',
        requestSubmitted: 'Request submitted',
        theRequestHasBeenSubmittedSuccessfully: 'The request has been submitted successfully.',
        requestValidated: 'Request validated',
        validateRequest: 'Validate request',
        rejectRequest: 'Reject request',
        treatRequest: 'Treat request',

        approuved_users: 'Approuved users',
        pending_users: 'Pending users',


        supervisor: 'Supervisor',
        companyOrEntity: 'Company or Entity',
        division: 'Division',
        departement: 'Departement',
        unit: 'Unit',

        theRequestHasBeenSuccessfullyValidated: 'The request has been successfully validated.',
        requestRejected: 'Request rejected',
        theRequestWasSuccessfullyRejected: 'The request was successfully rejected.',
        requestDeleted: 'Request deleted',
        theRequestWasSuccessfullyDeleted: 'The request was successfully deleted.',

        subjectRequest: 'Subject request',
        descriptionRequest: 'Description request',
        optional: 'Optional',
        mandatory: 'Mandatory',

        requestSentBy: 'Request sent by',
        mailAddress: 'Mail address',

        // STATUS
        draft: 'Draft',
        pending: 'Pending',
        participate: 'Participate',
        approve: 'Approve',
        dismiss: 'Dismiss',
        validate: 'Validate',
        validated: 'Validated',
        treated: 'Treated',
        rejected: 'Rejected',
        requestType: 'Request type',
        statusRequest: 'Status request',
        documentType: 'Document type',
        administrativeDocument: 'Administrative document',

        departureDate: 'Date of departure',
        departureTime: 'Departure time',
        returnDate: 'Return date',
        returnTime: 'Return time',

        requestDetails: 'Request Details',
        eventDetails: 'Event details',

        pendingRequest: 'Pending request',
        pendingRequests: 'Pending requests',
        requestApproved: 'Request approved',
        requestsApproved: 'Requests approved',

        location: 'Location',
        day: 'day',
        days: 'days',
        date: 'Date',
        at: 'at',

        mandatory: 'Mandatory',
        submit: 'Submit',
        delete: 'Delete',
        numberOfDays: 'Number of days',
        save: 'Save',

        select_the_type_of_request: 'Select the type of request',
        select_the_document_type: 'Select the document type.',
        enter_departure_date: 'Enter departure date.',
        enter_departure_time: 'Enter departure time.',
        enter_return_date: 'Enter return date.',
        enter_return_time: 'Enter return time.',

        selectTheDocument: 'Select the document',
        acquiredDayOff: 'Acquired day off',
        lastupdatedOn: 'Last updated on',

        myAIAssistant: 'My AI Assistant',
        myAIAssistantDesc: 'Smart computer virtual assistant. Solve your most common IT queries by automating them with a smart chatbot.',
        chatDesc: 'Learn how to use chat based language models.',

        textCompletion: 'Text completion',
        textCompletionDesc: 'Learn how to generate or edit text.',

        embeddings: 'Embeddings',
        embeddingsDesc: 'Learn how to search, classify and compare text.',

        speechToText: 'Speech to text',
        speechToTextDesc: 'Learn how to turn audio into text.',


        speechToTranslateInEnglish: 'Speech to Translate in English',
        speechToTranslateInEnglishDesc: 'Translates audio into English.',


        imageGeneration: 'Image generation',
        imageGenerationDesc: 'Learn how to generate or edit images.',

        fineTuning: 'Fine-tuning',
        fineTuningDesc: 'Learn how to train a model for your use case.',

        history: 'History',
        newConversation: 'New conversation',
        sorry: 'Sorry',
        anErrorHasOccurredPleaseTryAgain: 'An error has occurred. Please try again',

        youAreNotAuthorizedToUseThisService: 'You are not authorized to use this service.',
        sorryYouHaveReachedTheAuthorizedLimit: 'Sorry, you have reached your authorized limit for this service.',

        // ADD PAGE
        my_accounts: 'My accounts',
        add_an_account: 'Add an account',
        login_to: 'Login to',
        delete_this_account: 'Delete this account',
        this_account_has_already_been_added: 'This account has already been added.',

        // CREATE PAGE
        my_pages: 'My pages',
        create_a_page: 'Create a page',
        create_a_page_description: 'Your colleagues come to your Page to learn more about you. Please enter any information they may require.',


        pageName: 'Page name',
        pageNameError: 'Enter the page name',
        pageNickname: 'Page nickname',
        pageNicknameError: 'Entrer the page nickname',
        descriptionOfThePage: 'Description of the page',
        pageVisibility: 'Page de la page',
        descriptionOfThePageError: 'Enter the page description',
        emailOfThePage: 'Email of the page',
        emailOfThePageError: 'Enter the Email of the page',
        country: 'Country',
        countryError: 'Select a country',
        phone: 'Phone',
        category: 'Category',
        categories: 'Categories',
        webSite: 'Website',
        createPage: 'Create page',
        myPages: 'My pages',
        pageCreated: 'Page created',
        pageCreatedSuccessfully: 'Page created successfully.',
        thisNicknameIsAlreadyInUse: "This nickname is already in use.",

        loginTo: 'Login to',
        pageSetting: 'Page settings',

        inviteAnAdministrator: 'Invite an administrator',
        inviteAnAdministratorDesc: 'Send a moderation proposal to a user.',

        listOfModerationRequests: 'List of moderation requests',
        listOfModerationRequestsDesc: 'Management of moderation requests.',

        noModerationRequestAvailable: 'No moderation request available',
        noUserFound: 'No user found, launch a search.',
        search: 'Search...',
        selectARole: 'Select a role.',
        inviteConfirmMessage: 'Would you like to send a moderation request to this user ?',
        inviteSentMessageTitle: 'Moderation request sent',
        inviteSentMessageDesc: 'Moderation request sent successfully.',
        inviteErrorMessage: 'Moderation request already sent to this user.',
        invited: 'Invited',
        invite: 'Invite',
        confirm: 'Confirm',
        cancel: 'Cancel',
        delete: 'Delete',
        deleteRequest: 'Delete request',
        deleteRequestDesc: 'Are you sure you want to delete this request ?',

        moderationConfirmMessage: 'sent you a moderation request, would you like to be a moderator of this page ?',
        moderationRequestAccepted: 'Moderation request accepted',
        moderationRequestAcceptedDesc: 'Moderation request accepted successfully.',

        no_data_available: 'No data available',
        no_request_available: 'No request available',

       
    }
}
export default en;