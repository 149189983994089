import React from 'react'
import { useState } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Profile from '../ProfileContent/ProfileContent'
import './ProfilePicture.style.css'
import { useEffect } from 'react'

const ProfilePicture = ({
    user,
    uri,
    size = 30,
}) => {

    const [showProfile, setShowProfile] = useState(false)
    

    return (
        <Link to={`/profiles/user/${user?.user_username}`}>
            <div>
                <img src={uri ? uri : user?.user_civility === "F" ? require('assets/images/bg_women.jpeg') : require('assets/images/background_opinion.jpg')}
                    className='profile-picture-componant'
                    width={size}
                    height={size}
                    style={{ borderRadius: '100%', backgroundColor: 'white' }}
                    alt=""
                />

                {/* RENDER VIEW PROFILE */}
                <Profile
                    user_id={user?.user_id}
                    show={showProfile}
                    setShow={setShowProfile}
                />
            </div>
        </Link>
    )
}

export default ProfilePicture